var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0 justify-content-center"},[_c('div',{staticClass:"\n        d-flex\n        align-items-center\n        justify-content-between\n        p-1\n        pl-0\n        col-md-9 col-12\n      "},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h2',{staticClass:"content-header-title m-0 pr-1 mr-1",staticStyle:{"border-right":"1px solid #e2e2e2"}},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"},[_c('h3',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.civilityTranslate(_vm.collaboratorForm.civility) + _vm.collaboratorForm.firstName + " " + _vm.collaboratorForm.lastName)+" ")])])]),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])]),(
      _vm.isLoadingCollaborator ||
      _vm.isCreatingCollaborator ||
      _vm.isUpdatingCollaborator
    )?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"content-scrollable-sticky m-0 h-100 justify-content-center"},[_c('b-col',{staticClass:"content-scrollable-sticky-main",attrs:{"cols":"12","md":"9"}},[_c('validation-observer',{ref:"formCreateCollaborator"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Civilité","label-for":"civility"}},[_c('validation-provider',{attrs:{"name":"Civility"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"civility","state":errors.length ? false : null,"reduce":function (type) { return type.value; },"clearable":false,"options":_vm.optionCivility},model:{value:(_vm.collaboratorForm.civility),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "civility", $$v)},expression:"collaboratorForm.civility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Nom *","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.lastName),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "lastName", $$v)},expression:"collaboratorForm.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom *","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.firstName),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "firstName", $$v)},expression:"collaboratorForm.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.email),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "email", $$v)},expression:"collaboratorForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone principal","label-for":"phoneNumber"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phoneNumber","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "phoneNumber", $$v)},expression:"collaboratorForm.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone secondaire","label-for":"secondaryPhoneNumber"}},[_c('validation-provider',{attrs:{"name":"secondaryPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"secondaryPhoneNumber","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.secondaryPhoneNumber),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "secondaryPhoneNumber", $$v)},expression:"collaboratorForm.secondaryPhoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Métier *","label-for":"jobId"}},[_c('validation-provider',{attrs:{"name":"JobId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"jobId","state":errors.length ? false : null,"reduce":function (type) { return type.id; },"options":_vm.jobsList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true}],null,true),model:{value:(_vm.collaboratorForm.jobId),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "jobId", $$v)},expression:"collaboratorForm.jobId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Type de contrat *","label-for":"contractId"}},[_c('validation-provider',{attrs:{"name":"ContractRef","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contractId","state":errors.length ? false : null,"reduce":function (type) { return type.id; },"options":_vm.contractsList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true}],null,true),model:{value:(_vm.collaboratorForm.contractId),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "contractId", $$v)},expression:"collaboratorForm.contractId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Matricule","label-for":"matricule"}},[_c('validation-provider',{attrs:{"name":"Matricule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"matricule","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.matricule),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "matricule", $$v)},expression:"collaboratorForm.matricule"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Commentaire","label-for":"commentaire"}},[_c('vs-textarea',{staticStyle:{"min-height":"140px","font-size":"1rem"},attrs:{"height":"140px"},model:{value:(_vm.collaboratorForm.comments),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "comments", $$v)},expression:"collaboratorForm.comments"}})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.displayFormComplement}},[_vm._v(" "+_vm._s(_vm.displayMore ? "- Masquer les informations complémentaires" : "+ Afficher les informations complémentaires")+" ")])])],1)],1),(_vm.displayMore)?_c('div',{attrs:{"id":"complement"}},[_c('vs-divider',{attrs:{"color":"#999999"}},[_vm._v(" Paramètres du collaborateur ")]),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Collaborateur associé à l'utilisateur","label-for":"userId"}},[_c('v-select',{attrs:{"id":"userId","reduce":function (elem) { return elem.value; },"options":_vm.usersList.map(function (elem) {
                        return {
                          label: elem.firstName + ' ' + elem.lastName,
                          value: elem.id,
                        };
                      })},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true}],null,false,3882475864),model:{value:(_vm.collaboratorForm.userId),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "userId", $$v)},expression:"collaboratorForm.userId"}})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('b-form-checkbox',{staticClass:".custom-control-primary.mt-50",model:{value:(_vm.collaboratorForm.useCrm),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "useCrm", $$v)},expression:"collaboratorForm.useCrm"}},[_vm._v(" Inclure le collaborateur dans les stats CRM ")])],1)],1),_c('vs-divider',{attrs:{"color":"#999999"}},[_vm._v(" Informations liés au contrat ")]),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("N° de sécurité sociale")]),_c('validation-provider',{attrs:{"name":"socialSecurityNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"socialSecurityNumber"},model:{value:(_vm.collaboratorForm.socialSecurityNumber),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "socialSecurityNumber", $$v)},expression:"collaboratorForm.socialSecurityNumber"}})]}}],null,false,102189928)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Coût horaire brut")]),_c('validation-provider',{attrs:{"name":"grossDailyCost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"type":"number","step":"0.01","id":"grossDailyCost"},model:{value:(_vm.collaboratorForm.grossDailyCost),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "grossDailyCost", $$v)},expression:"collaboratorForm.grossDailyCost"}})]}}],null,false,1992164222)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Durée travail / jour ")]),_c('validation-provider',{attrs:{"name":"hoursByDay"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"type":"number","step":"0.25","id":"hoursByDay"},model:{value:(_vm.collaboratorForm.hoursByDay),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "hoursByDay", $$v)},expression:"collaboratorForm.hoursByDay"}})]}}],null,false,603638392)})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('b-form-checkbox',{staticClass:".custom-control-primary.mt-50",model:{value:(_vm.collaboratorForm.reportEntryMode),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "reportEntryMode", $$v)},expression:"collaboratorForm.reportEntryMode"}},[_vm._v(" Saisi son reporting en mode créneau horaire ")])],1)],1),_c('vs-divider',{attrs:{"color":"#999999"}},[_vm._v(" Informations personnelles ")]),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"address"}},[_vm._v("Adresse")]),_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length ? false : null},model:{value:(_vm.collaboratorForm.address),callback:function ($$v) {_vm.$set(_vm.collaboratorForm, "address", $$v)},expression:"collaboratorForm.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3852607153)})],1)],1)],1):_vm._e()])],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1),(_vm.collaboratorForm.id)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archiveCollaboratorLocal(
                    _vm.collaboratorForm.id,
                    _vm.collaboratorForm.firstName +
                      ' ' +
                      _vm.collaboratorForm.lastName
                  )}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.collaboratorForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.collaboratorForm.id ? "Modifier" : "Ajouter")+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"loading-bg-inner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('div',{staticClass:"mt-5"},[_c('br'),_c('br'),_c('br'),_vm._v(" Chargement des détails du collaborateur... ")])])}]

export { render, staticRenderFns }