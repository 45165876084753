<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <div
        class="
          d-flex
          align-items-center
          justify-content-between
          p-1
          pl-0
          col-md-9 col-12
        "
      >
        <div class="d-flex align-items-center">
          <h2
            class="content-header-title m-0 pr-1 mr-1"
            style="border-right: 1px solid #e2e2e2"
          >
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="content-header-search mr-1">
            <h3 class="m-0">
              {{
                civilityTranslate(collaboratorForm.civility) +
                collaboratorForm.firstName +
                " " +
                collaboratorForm.lastName
              }}
            </h3>
          </div>
        </div>
        <div class="content-header-actions d-flex">
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="24"
            @click="cancel()"
          />
        </div>
      </div>
    </b-row>
    <div
      v-if="
        isLoadingCollaborator ||
        isCreatingCollaborator ||
        isUpdatingCollaborator
      "
      class="content-loader-center m-0 h-100"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du collaborateur...
        </div>
      </div>
    </div>
    <b-row
      v-else
      class="content-scrollable-sticky m-0 h-100 justify-content-center"
    >
      <b-col cols="12" md="9" class="content-scrollable-sticky-main">
        <validation-observer ref="formCreateCollaborator">
          <b-form autocomplete="off">
            <div id="general" class="my-0">
              <b-row class="my-0 col-mobile">
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group label="Civilité" label-for="civility">
                    <validation-provider name="Civility">
                      <template v-slot="{ errors }">
                        <v-select
                          id="civility"
                          :state="errors.length ? false : null"
                          :reduce="(type) => type.value"
                          v-model="collaboratorForm.civility"
                          :clearable="false"
                          :options="optionCivility"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group label="Nom *" label-for="lastName">
                    <validation-provider name="lastName" rules="required">
                      <template v-slot="{ errors }">
                        <b-form-input
                          id="lastName"
                          v-model="collaboratorForm.lastName"
                          :state="errors.length ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group label="Prénom *" label-for="firstName">
                    <validation-provider name="firstName" rules="required">
                      <template v-slot="{ errors }">
                        <b-form-input
                          id="firstName"
                          v-model="collaboratorForm.firstName"
                          :state="errors.length ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0 col-mobile">
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider name="email" rules="email">
                      <template v-slot="{ errors }">
                        <b-form-input
                          id="email"
                          v-model="collaboratorForm.email"
                          :state="errors.length ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group
                    label="Téléphone principal"
                    label-for="phoneNumber"
                  >
                    <validation-provider name="Phone">
                      <template v-slot="{ errors }">
                        <b-form-input
                          id="phoneNumber"
                          v-model="collaboratorForm.phoneNumber"
                          :state="errors.length ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group
                    label="Téléphone secondaire"
                    label-for="secondaryPhoneNumber"
                  >
                    <validation-provider name="secondaryPhoneNumber">
                      <template v-slot="{ errors }">
                        <b-form-input
                          id="secondaryPhoneNumber"
                          v-model="collaboratorForm.secondaryPhoneNumber"
                          :state="errors.length ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0 col-mobile">
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group label="Métier *" label-for="jobId">
                    <validation-provider name="JobId" rules="required">
                      <template v-slot="{ errors }">
                        <v-select
                          id="jobId"
                          :state="errors.length ? false : null"
                          :reduce="(type) => type.id"
                          v-model="collaboratorForm.jobId"
                          :options="jobsList"
                        >
                          <template v-slot:no-options="">
                            <template>
                              Aucun r&eacute;sultat trouv&eacute;
                            </template>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group
                    label="Type de contrat *"
                    label-for="contractId"
                  >
                    <validation-provider name="ContractRef" rules="required">
                      <template v-slot="{ errors }">
                        <v-select
                          id="contractId"
                          :state="errors.length ? false : null"
                          :reduce="(type) => type.id"
                          v-model="collaboratorForm.contractId"
                          :options="contractsList"
                        >
                          <template v-slot:no-options="">
                            <template>
                              Aucun r&eacute;sultat trouv&eacute;
                            </template>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4" class="py-0 mb-1">
                  <b-form-group label="Matricule" label-for="matricule">
                    <validation-provider name="Matricule">
                      <template v-slot="{ errors }">
                        <b-form-input
                          id="matricule"
                          v-model="collaboratorForm.matricule"
                          :state="errors.length ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </template>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-0">
                  <b-form-group label="Commentaire" label-for="commentaire">
                    <vs-textarea
                      v-model="collaboratorForm.comments"
                      height="140px"
                      style="min-height: 140px; font-size: 1rem"
                    ></vs-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <small
                    class="cursor-pointer text-primary"
                    @click="displayFormComplement"
                  >
                    {{
                      displayMore
                        ? "- Masquer les informations complémentaires"
                        : "+ Afficher les informations complémentaires"
                    }}
                  </small>
                </b-col>
              </b-row>
            </div>
            <div id="complement" v-if="displayMore">
              <vs-divider color="#999999">
                Paramètres du collaborateur
              </vs-divider>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Collaborateur associé à l'utilisateur"
                    label-for="userId"
                  >
                    <v-select
                      id="userId"
                      :reduce="(elem) => elem.value"
                      v-model="collaboratorForm.userId"
                      :options="
                        usersList.map((elem) => {
                          return {
                            label: elem.firstName + ' ' + elem.lastName,
                            value: elem.id,
                          };
                        })
                      "
                    >
                      <template v-slot:no-options="">
                        <template>
                          Aucun r&eacute;sultat trouv&eacute;
                        </template>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" md="4" class="py-0">
                  <b-form-checkbox
                    class=".custom-control-primary.mt-50"
                    v-model="collaboratorForm.useCrm"
                  >
                    Inclure le collaborateur dans les stats CRM
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <vs-divider color="#999999">
                Informations liés au contrat
              </vs-divider>
              <b-row class="my-0">
                <b-col cols="12" md="4" class="py-0">
                  <label for="address'">N° de sécurité sociale</label>
                  <validation-provider name="socialSecurityNumber">
                    <template v-slot="{ errors }">
                      <b-form-input
                        class="mb-1"
                        id="socialSecurityNumber"
                        v-model="collaboratorForm.socialSecurityNumber"
                      ></b-form-input>
                    </template>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="4" class="py-0">
                  <label for="address'">Coût horaire brut</label>
                  <validation-provider name="grossDailyCost">
                    <template v-slot="{ errors }">
                      <b-form-input
                        type="number"
                        step="0.01"
                        class="mb-1"
                        id="grossDailyCost"
                        v-model="collaboratorForm.grossDailyCost"
                      ></b-form-input>
                    </template>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="4" class="py-0">
                  <label for="address'">Durée travail / jour </label>
                  <validation-provider name="hoursByDay">
                    <template v-slot="{ errors }">
                      <b-form-input
                        type="number"
                        step="0.25"
                        class="mb-1"
                        id="hoursByDay"
                        v-model="collaboratorForm.hoursByDay"
                      ></b-form-input>
                    </template>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="4" class="py-0">
                  <b-form-checkbox
                    class=".custom-control-primary.mt-50"
                    v-model="collaboratorForm.reportEntryMode"
                  >
                    Saisi son reporting en mode créneau horaire
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <vs-divider color="#999999">
                Informations personnelles
              </vs-divider>
              <b-row class="my-0">
                <b-col cols="12" class="py-0">
                  <label for="address">Adresse</label>
                  <validation-provider name="Address">
                    <template v-slot="{ errors }">
                      <b-form-input
                        id="address"
                        v-model="collaboratorForm.address"
                        :state="errors.length ? false : null"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </template>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button variant="outline-primary" @click="cancel()">
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="collaboratorForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="
                    archiveCollaboratorLocal(
                      collaboratorForm.id,
                      collaboratorForm.firstName +
                        ' ' +
                        collaboratorForm.lastName
                    )
                  "
                >
                  <feather-icon icon="ArchiveIcon" />
                </b-button>
              </div>

              <b-button variant="primary" @click="create">
                <feather-icon icon="SaveIcon" v-if="collaboratorForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ collaboratorForm.id ? "Modifier" : "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ECivility } from "@/types/api-orisis/enums/enums";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { email, required, url } from "@validations";
import { debounce } from "lodash";
import {
  ValidationObserver,
  ValidationProvider,
  configure,
  localize,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Autocomplete from "vuejs-auto-complete";

configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scrolled: false,
      autocompleteCollaboratorName: "",
      autocompleteCollaboratorNameData: [],
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine: {},
      collaboratorForm: {},
      collaboratorType: {},
    };
  },
  async created() {
    this.popupCollaboratorEvent(this.id);
    this.getContracts({});
    this.getJobs({});
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    civilityTranslate,
    ...mapActions([
      "createCollaborator",
      "getCollaboratorById",
      "updateCollaborator",
      "archiveCollaborators",
      "getContracts",
      "getJobs",
      "getUsersByInstitution",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupCollaboratorEvent(id) {
      this.getUsersByInstitution({});
      this.getJobs({});
      this.getContracts({});
      if (id != "") {
        this.getCollaboratorById({ collaboratorId: id }).then((res) => {
          this.collaboratorForm = res;
          (this.collaboratorForm.reportEntryMode =
            this.collaboratorForm.reportEntryMode == 1 ? true : false),
            (this.collaboratorForm.jobId = res.job.id);
          this.collaboratorForm.contractId = res.contract.id;
          this.dataOrigine = JSON.stringify(this.collaboratorForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.collaboratorForm = {
        id: 0,
        civility: 1,
        lastName: "",
        firstName: "",
        email: "",
        phoneNumber: "",
        secondaryPhoneNumber: "",
        jobId: null,
        contractId: null,
        matricule: null,
        comments: "",
        address: "",
        addressComplement: "",
        zipCode: "",
        city: "",
        country: "",
        socialSecurityNumber: "",
        travelMax: 0,
        latitude: null,
        longitude: null,
        grossDailyCost: 0,
        hoursByDay: 7,
        useCrm: false,
        reportEntryMode: false,
      };
      this.dataOrigine = JSON.stringify(this.collaboratorForm);
    },
    displayFormComplement() {
      this.displayMore = !this.displayMore;
      this.$nextTick(() => {
        this.deteleAutocomplete();
      });
    },
    create(e) {
      e.preventDefault();
      this.$refs.formCreateCollaborator
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.collaboratorForm.id) {
              this.updateCollaborator({
                collaborator: {
                  ...this.collaboratorForm,
                  reportEntryMode:
                    this.collaboratorForm.reportEntryMode == true ? 1 : 0,
                  grossDailyCost: parseInt(
                    this.collaboratorForm.grossDailyCost
                  ),
                  hoursByDay: parseInt(this.collaboratorForm.hoursByDay),
                },
              });
            } else
              this.createCollaborator({
                collaborator: {
                  ...this.collaboratorForm,
                  reportEntryMode:
                    this.collaboratorForm.reportEntryMode == true ? 1 : 0,
                  grossDailyCost: parseInt(
                    this.collaboratorForm.grossDailyCost
                  ),
                  hoursByDay: parseInt(this.collaboratorForm.hoursByDay),
                },
              });

            this.$nextTick(() => {
              this.$tabs.close({ to: "/collaborators" });
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.collaboratorForm.address}&autocomplete=0&=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.collaboratorForm.address = item.properties.name;
      this.collaboratorForm.city = item.properties.city;
      this.collaboratorForm.zipCode = item.properties.postcode;
      this.collaboratorForm.latitude = item.geometry.coordinates[1];
      this.collaboratorForm.longitude = item.geometry.coordinates[0];
      this.collaboratorForm.country = "France";
    },

    // Controls from
    archiveCollaboratorLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveCollaborators({ collaboratorIds: [id] });
            this.$tabs.close();
          }
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.collaboratorForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "usersList",
      "contractsList",
      "jobsList",
      "isLoadingCollaborator",
      "isCreatingCollaborator",
      "isUpdatingCollaborator",
      "institutionSelected",
    ]),
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 768px) {
  .col-mobile {
    flex-direction: column !important;
  }
}
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
